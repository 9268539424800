.App {
  text-align: center;
  /* font-family: 'DM Sans', sans-serif; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typewriter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 400px;
  height: 300px;
  /* background-color: magenta; */
  padding: 15px;
  line-height: 50px;
}

.typewriter-text {
  font-size: 20px;
  font-family: cursive;
  /* color: white; */
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


/* WAFFY CSS */
.waffy-pay-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.waffy-pay-button:hover {
  background-color: #0056b3;
}

#waffy-payment-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

#waffy-payment-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  height: 70%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

#waffy-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
}

#waffy-close-button:hover {
  color: #0056b3;
}

.waffy-input-container {
  display: flex;
  margin: 20px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.waffy-input-container input {
  padding: 10px;
  font-size: 16px;
  width: 250px;
  border: 1px solid #007bff;
  border-radius: 5px;
}

.waffy-input-container input:focus {
  outline: 0;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
