.css-1tq8nf4-MuiFormControl-root-MuiTextField-root input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border-radius: 100%;
  box-sizing: content-box;
  background: none;
  height: 20px;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  width: 40px;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 10px 10px;
}

// Country DropDown Hover CSS
.react-tel-input .selected-flag:hover {
  background-color: #000;
}
.react-tel-input .selected-flag:focus {
  background-color: #000;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #000;
}

// CSS for DataGrid ONCell Click Started
.cursor-pointer {
  cursor: pointer;
}
// CSS for DataGrid ONCell Click Ended

.hideNumberAppearance {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.img1 {
  height: 600px;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.img1:hover {
  transform: translateY(50px);
  opacity: 0.8;
}

@media (min-width: 900px) and (max-width: 997px) {
  .img1 {
    height: 290px; /* Set the height for screens between 1500px and 1720px */
  }
}
@media (min-width: 997px) and (max-width: 1330px) {
  .img1 {
    height: 340px; /* Set the height for screens between 1500px and 1720px */
  }
}
@media (min-width: 1330px) and (max-width: 1500px) {
  .img1 {
    height: 440px; /* Set the height for screens between 1500px and 1720px */
  }
}
@media (min-width: 1500px) and (max-width: 1720px) {
  .img1 {
    height: 500px; /* Set the height for screens between 1500px and 1720px */
  }
}

.wishlist-icon svg path {
  transition: stroke 0.3s ease;
}

.whishlist-loading:hover .wishlist-icon svg path {
  stroke: #ffffff;
}

.CategorySliderImage:hover {
  transform: "scale(1.1)";
}
